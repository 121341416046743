$white: #ffffff;
$black: #000000;
$primary1: #ffbe33;
$primary2: #222831;
$textCol: #1f1f1f;

// import fonts -  font-family: 'Open Sans', sans-serif and  font-family: 'Dancing Script', cursive;

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Open+Sans:wght@400;600;700&display=swap');

@mixin main-font {
  font-family: "Open Sans", sans-serif;
}

@mixin second-font {
  font-family: 'Dancing Script', cursive;
}

@mixin hero_btn($col1, $col2, $pad1, $pad2, $bRadius) {
  display: inline-block;
  padding: $pad1 $pad2;
  background-color: $col1;
  color: $col2;
  border-radius: $bRadius;
  transition: all 0.3s;
  border: none;

  &:hover {
    background-color: darken($color: $col1, $amount: 15);
  }
}

@mixin upperBold {
  text-transform: uppercase;
  font-weight: bold;
}

body {
  @include main-font;
  color: #0c0c0c;
  background-color: #ffffff;
  overflow-x: hidden;
}

.layout_padding {
  padding: 90px 0;
}

.layout_padding2 {
  padding: 75px 0;
}

.layout_padding2-top {
  padding-top: 75px;
}

.layout_padding2-bottom {
  padding-bottom: 75px;
}

.layout_padding-top {
  padding-top: 90px;
}

.layout_padding-bottom {
  padding-bottom: 90px;
}

h1,
h2 {
  @include second-font();
}

.heading_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2 {
    position: relative;
    margin-bottom: 0;
    font-size: 2.5rem;
    font-weight: bold;

    span {
      color: $primary1;
    }
  }

  p {
    margin-top: 10px;
    margin-bottom: 0;
  }

  &.heading_center {
    align-items: center;
    text-align: center;
  }
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  box-shadow: none;
}

/*header section*/
.hero_area {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.bg-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.sub_page {
  .hero_area {
    min-height: auto;

    .bg-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: right top;
      }
    }
  }

}

.header_section {
  padding: 15px 0;

  .container-fluid {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.navbar-brand {
  @include second-font();

  span {
    font-weight: bold;
    font-size: 32px;
    color: $white;
  }
}

.custom_nav-container {
  padding: 0;

  .navbar-nav {
    padding-left: 18%;

    .nav-item {
      .nav-link {
        padding: 5px 20px;
        color: $white;
        text-align: center;
        text-transform: uppercase;
        border-radius: 5px;
        transition: all 0.3s;
      }

      &:hover,
      &.active {
        .nav-link {
          color: $primary1;
        }
      }
    }
  }
}

.custom_nav-container .nav_search-btn {
  width: auto;
  height: auto;
  padding: 0;
  border: none;
  color: $white;
  margin: 0 10px;

  &:hover {
    color: $primary1;
  }
}

.user_option {
  display: flex;
  align-items: center;

  a {
    margin: 0 10px;
  }

  .user_link {
    color: $white;


    &:hover {
      color: $primary1;

    }
  }

  .cart_link {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 17px;
      height: auto;
      fill: $white;
      margin-bottom: 2px;
    }

    &:hover {

      svg {
        fill: $primary1;
      }
    }
  }

  .order_online {
    @include hero_btn($primary1, $white, 8px, 30px, 45px);
  }
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler {
  padding: 0;
  width: 37px;
  height: 42px;
  transition: all 0.3s;

  span {
    display: block;
    width: 35px;
    height: 4px;
    background-color: $white;
    margin: 7px 0;
    transition: all 0.3s;
    position: relative;
    border-radius: 5px;
    transition: all 0.3s;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $white;
      top: -10px;
      border-radius: 5px;
      transition: all 0.3s;
    }

    &::after {
      top: 10px;
    }
  }

  &[aria-expanded="true"] {
    transform: rotate(360deg);

    span {
      transform: rotate(45deg);

      &::before,
      &::after {
        transform: rotate(90deg);
        top: 0;
      }
    }

  }
}

/*end header section*/

/* slider section */
.slider_section {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  padding: 45px 0 75px 0;

  .row {
    align-items: center;
  }

  #customCarousel1 {
    width: 100%;
    position: unset;
  }

  .detail-box {
    color: $white;
    margin-bottom: 145px;

    h1 {
      font-size: 3.5rem;
      font-weight: bold;
      margin-bottom: 15px;
    }

    p {
      font-size: 14px;
    }

    a {
      @include hero_btn($primary1, $white, 10px, 45px, 45px);
      margin-top: 10px;
    }

  }

  .img-box {
    img {
      width: 100%;
    }

  }


  .carousel-indicators {
    position: unset;
    margin: 0;
    justify-content: flex-start;
    align-items: center;

    li {
      background-color: $white;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      opacity: 1;

      &.active {
        width: 20px;
        height: 20px;
        background-color: $primary1;
      }
    }
  }
}

// end slider section

// offer section

.offer_section {
  position: relative;
  padding-top: 45px;

  .box {
    display: flex;
    align-items: center;
    margin-top: 45px;
    border-radius: 5px;
    padding: 20px 15px;
    background-color: $primary2;
    color: $white;

    .img-box {
      width: 175px;
      min-width: 175px;
      height: 175px;
      margin-right: 15px;
      position: relative;
      transition: all .1.5s;
      border-radius: 100%;
      border: 5px solid $primary1;
      overflow: hidden;

      img {
        width: 100%;
        transition: all .2s;
      }
    }

    .detail-box {

      h5 {
        @include second-font();
        font-size: 24px;
        margin: 0;
      }

      h6 {
        @include second-font();
        margin: 10px 0;

        span {
          font-size: 2.5rem;
          font-weight: bold
        }
      }

      p {}

      a {
        @include hero_btn($primary1, $white, 10px, 30px, 45px);

        svg {
          width: 20px;
          height: auto;
          margin-left: 5px;
          fill: $white;
        }
      }
    }

    &:hover {
      .img-box {

        img {
          transform: scale(1.1);
        }
      }
    }
  }

}

// end offer section


// food section

.food_section {

  .filters_menu {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    margin: 45px 0 20px 0;

    li {
      padding: 7px 25px;
      cursor: pointer;
      border-radius: 25px;

      &.active {
        background-color: $primary2;
        color: $white;
      }
    }
  }

  .box {
    position: relative;
    margin-top: 25px;
    background-color: $white;
    border-radius: 10px;
    color: $white;
    border-radius: 15px;
    overflow: hidden;
    background: linear-gradient(to bottom, #f1f2f3 25px, $primary2 25px);

    .img-box {
      background: #f1f2f3;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 215px;
      border-radius: 0 0 0 45px;
      margin: -1px;
      padding: 25px;

      img {
        max-width: 100%;
        max-height: 145px;
        transition: all .2s;
      }
    }

    .detail-box {
      padding: 25px;

      h5 {
        font-weight: 600;
      }

      p {
        font-size: 15px;
      }

      h6 {
        margin-top: 10px;
      }
    }

    .options {
      display: flex;
      justify-content: space-between;

      a {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: $primary1;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 18px;
          height: auto;
          fill: $white;
        }
      }
    }

    &:hover {
      .img-box {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  .btn-box {
    display: flex;
    justify-content: center;
    margin-top: 45px;

    a {
      @include hero_btn($primary1, $white, 10px, 55px, 45px);
    }
  }
}

// end food section

// about section
.about_section {
  background: $primary2;
  color: $white;

  .row {
    align-items: center;
  }

  .img-box {
    position: relative;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      max-width: 445px;
      position: relative;
      z-index: 2;
    }

  }

  .detail-box {
    p {
      margin-top: 15px;
    }

    a {
      @include hero_btn($primary1, $white, 10px, 45px, 45px);
      margin-top: 15px;
    }
  }
}

// end about section

// book section
.book_section {
  position: relative;

  .heading_container {
    margin-bottom: 25px;
  }


  .form_container {

    .form-control {
      width: 100%;
      border: none;
      height: 50px;
      margin-bottom: 25px;
      padding-left: 25px;
      border: 1px solid #999;
      outline: none;
      color: $black;
      border-radius: 5px;
      outline: none;
      box-shadow: none;

      &::placeholder {
        color: #222222;
      }


    }

    .nice-select .current {
      font-size: 16px;
    }

    button {
      margin-top: 15px;
      border: none;
      text-transform: uppercase;
      @include hero_btn($primary1, $white, 10px, 55px, 45px);
    }
  }

  .map_container {
    width: 100%;
    height: 345px;
    border-radius: 10px;
    overflow: hidden;

    #googleMap {
      height: 100%;
      min-height: 100%;
      width: 100%;
    }
  }

}

// end book section


/* client section start */

.client_section {
  .heading_container {
    margin-bottom: 30px;
  }

  .box {
    display: flex;
    flex-direction: column;
    margin: 15px;

    .img-box {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 115px;
      position: relative;
      margin-top: 30px;

      img {
        border-radius: 100%;
        border: 5px solid $primary1;
        position: relative;
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        width: 20px;
        height: 20px;
        background-color: $primary1;
        transform: rotate(45deg) translateX(-50%);
      }
    }

    .detail-box {
      background-color: $primary2;
      color: $white;
      padding: 25px 25px 15px 25px;
      border-radius: 5px;

      h6 {
        font-weight: 600;
        font-size: 18px;
        margin: 15px 0 5px 0;
      }

      p {
        margin-bottom: 0;
        font-size: 15px;
        margin-bottom: 10px;
      }
    }
  }

  .owl-carousel {
    .owl-nav {
      display: flex;
      justify-content: center;
      margin-top: 45px;
      padding: 0 15px;

      .owl-prev,
      .owl-next {
        width: 45px;
        height: 45px;
        background-color: $primary1;
        color: $white;
        outline: none;
        bottom: 0px;
        font-size: 24px;
        margin: 0 5px;
        border-radius: 100%;
      }
    }
  }
}

/* client section end */

// contact section
.contact_section {
  .heading_container {
    margin-bottom: 45px;
  }


  .form_container {
    .form-group {
      margin-bottom: 25px;
    }

    input {
      width: 100%;
      border: none;
      height: 50px;
      padding-left: 25px;
      background-color: transparent;
      border: 1px solid #ccc;
      outline: none;
      color: $black;
      border-radius: 5px;

      &::placeholder {
        color: #222222;
      }

      &.message-box {
        height: 120px;
        border-radius: 5px;
      }
    }

    .btn-box {
      display: flex;
      justify-content: center;
    }

    button {
      margin-top: 10px;
      border: none;
      text-transform: uppercase;
      @include hero_btn($primary1, $white, 10px, 55px, 0px);
    }
  }


}

// end contact section

/* footer section*/

.footer_section {
  background-color: $primary2;
  color: $white;
  padding: 75px 0 40px 0;
  text-align: center;

  h4 {
    font-size: 28px;
  }

  h4,
  .footer-logo {
    font-weight: 600;
    margin-bottom: 20px;
    @include second-font();
  }

  p {
    color: #dbdbdb;
  }

  .footer-col {
    margin-bottom: 30px;
  }

  .footer_contact {

    .contact_link_box {
      display: flex;
      flex-direction: column;

      a {
        margin: 5px 0;
        color: $white;

        i {
          margin-right: 5px;
        }

        &:hover {
          color: $primary1;
        }
      }
    }
  }

  .footer-logo {
    display: block;
    font-weight: bold;
    font-size: 38px;
    line-height: 1;
    color: $white;
  }

  .footer_social {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary2;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: $white;
      border-radius: 100%;
      margin: 0 2.5px;
      font-size: 18px;

      &:hover {
        color: $primary1;
      }
    }
  }



  .footer-info {
    text-align: center;
    margin-top: 25px;

    p {
      color: $white;
      margin: 0;

      a {
        color: inherit;
      }
    }
  }
}

// end footer section